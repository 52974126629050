<p-dialog [contentStyle]="{'overflow':'visible'}" [header]="header" [styleClass]="'custom-dialog dialog-vw-50'"
  [modal]="true" [baseZIndex]="10000" [closeOnEscape]="false" [closable]="true"
  [(visible)]="visible" [draggable]="false" [resizable]="false" (onHide)="onHide()">
  <p class="translated-paragraph">{{dialogText}}</p>
  <div class="form-group" *ngIf="isWithNote">
    <label for="reasonInput">{{'ADDNOTE.REASON' | translate}}:</label>
    <textarea class="form-control" id="reasonInput" [(ngModel)]="confirmDialog.optionalNote" placeholder="" rows="3"></textarea>
  </div>
  <div class="form-group" *ngIf="isWithPlannedNotification">
    <input class="mr-1" type="checkbox" [checked]="confirmDialog.isPlannedNotification"
      (change)="confirmDialog.isPlannedNotification = !confirmDialog.isPlannedNotification" id="plannedNotification">
    <label for="plannedNotification" translate>ADDNOTE.PLAN_NOTIFICATION</label>:
    <span class="p-0">
      <fa-icon size="xs" class="mx-1 text-secondary" [icon]="['fas', 'clock']"></fa-icon>
    </span>
    <div class="input-group pl-4" *ngIf="confirmDialog.isPlannedNotification">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
        </div>
      </div>
      <app-custom-datepicker [(value)]="confirmDialog.plannedNotificationDate" [minDate]="dateNow" class="input-group-text"
        [placeholder]="dateNow | date:'dd/MM/yyyy'">
      </app-custom-datepicker>
    </div>
  </div>
  <ng-content></ng-content> <!-- Add custom elements here -->
  <p-footer>
    <button type="button" class="btn btn-secondary " data-dismiss="modal" (click)="hide()" translate>
      GENERAL.CANCEL
    </button>
    <button [disabled]="updating" type="button" class="btn btn-primary ml-auto"
            (click)="approve()" translate>
      GENERAL.CONFIRM
    </button>
  </p-footer>
</p-dialog>
