import {Component, Input} from '@angular/core';
import {ContactPersonDto} from '../../../../model/dtos/client';

@Component({
  selector: 'app-contact-card-list',
  templateUrl: './contact-card-list.component.html',
  styleUrls: ['./contact-card-list.component.scss']
})
export class ContactCardListComponent {
  @Input() persons: ContactPersonDto[];
  isCollapsed: boolean;

  // When a default contact person is present show that one, otherwise pick the first
  get topContact() {
    const defaultContact = this.persons.filter(x => x.isDefaultContactPerson)[0];
    if (defaultContact) {
      return defaultContact;
    } else {
      return this.persons[0];
    }
  }

  get otherContacts() {
    return this.hasMoreContacts && this.persons.filter(x => x.id !== this.topContact.id);
  }

  get hasContacts() {
    return this.persons && this.persons.length > 0;
  }

  get hasMoreContacts() {
    return this.hasContacts && this.persons.length > 1;
  }

  constructor() {
    this.isCollapsed = true;
  }
}
