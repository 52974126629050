import {Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row" [ngClass]="{'bg-secondary p-3': to.darkMode}">
      <formly-field class="col" [ngClass]="{'bg-light rounded pt-3': to.darkMode}"
                    [field]="field"></formly-field>
      <div *ngIf="!to.onlyEdit" class="col-sm-2 d-flex align-items-center">
        <button aria-label="remove" class="btn btn-danger" type="button" (click)="remove(i)">
          <fa-icon [icon]="['fas', 'trash-alt']" size="lg"></fa-icon>
        </button>
      </div>
    </div>
    <div *ngIf="!to.onlyEdit" style="margin:30px 0;">
      <button class="btn btn-primary" type="button" (click)="add(undefined, to.initialModel)">{{ to.addText }}</button>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {
}
