import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';

@Injectable({
  providedIn: SharedModule,
})
export class CustomDatePickerService {
  locale: any;
  constructor(private translateService: TranslateService) {
    this.locale = {
      firstDayOfWeek: 1,
      dayNames: [
        this.translateService.instant('CALENDAR.DAYS.LONG.SUNDAY'),
        this.translateService.instant('CALENDAR.DAYS.LONG.MONDAY'),
        this.translateService.instant('CALENDAR.DAYS.LONG.TUESDAY'),
        this.translateService.instant('CALENDAR.DAYS.LONG.WEDNESDAY'),
        this.translateService.instant('CALENDAR.DAYS.LONG.THURSDAY'),
        this.translateService.instant('CALENDAR.DAYS.LONG.FRIDAY'),
        this.translateService.instant('CALENDAR.DAYS.LONG.SATURDAY')
      ],
      dayNameShort: [
        this.translateService.instant('CALENDAR.DAYS.SHORT.SUNDAY'),
        this.translateService.instant('CALENDAR.DAYS.SHORT.MONDAY'),
        this.translateService.instant('CALENDAR.DAYS.SHORT.TUESDAY'),
        this.translateService.instant('CALENDAR.DAYS.SHORT.WEDNESDAY'),
        this.translateService.instant('CALENDAR.DAYS.SHORT.THURSDAY'),
        this.translateService.instant('CALENDAR.DAYS.SHORT.FRIDAY'),
        this.translateService.instant('CALENDAR.DAYS.SHORT.SATURDAY')
      ],
      dayNamesMin: [
        this.translateService.instant('CALENDAR.DAYS.MIN.SUNDAY'),
        this.translateService.instant('CALENDAR.DAYS.MIN.MONDAY'),
        this.translateService.instant('CALENDAR.DAYS.MIN.TUESDAY'),
        this.translateService.instant('CALENDAR.DAYS.MIN.WEDNESDAY'),
        this.translateService.instant('CALENDAR.DAYS.MIN.THURSDAY'),
        this.translateService.instant('CALENDAR.DAYS.MIN.FRIDAY'),
        this.translateService.instant('CALENDAR.DAYS.MIN.SATURDAY')
      ],
      monthNames: [
        this.translateService.instant('CALENDAR.MONTHS.LONG.JANUARY'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.FEBRUARY'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.MARCH'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.APRIL'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.MAY'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.JUNE'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.JULY'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.AUGUST'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.SEPTEMBER'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.OCTOBER'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.NOVEMBER'),
        this.translateService.instant('CALENDAR.MONTHS.LONG.DECEMBER')
      ],
      monthNamesShort: [
        this.translateService.instant('CALENDAR.MONTHS.SHORT.JANUARY'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.FEBRUARY'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.MARCH'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.APRIL'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.MAY'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.JUNE'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.JULY'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.AUGUST'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.SEPTEMBER'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.OCTOBER'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.NOVEMBER'),
        this.translateService.instant('CALENDAR.MONTHS.SHORT.DECEMBER')
      ],
      today: this.translateService.instant('CALENDAR.TODAY'),
      clear: this.translateService.instant('CALENDAR.CLEAR'),
      dateFormat: 'd/mm/yy',
      weekHeader: ''
    };
  }
}
