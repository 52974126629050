import { PagedFilterModel } from './paged-filter.model';

export class DebtorFilter extends PagedFilterModel {
  legalName: string;
  onlyWithSaldo: boolean;
  isPaused: boolean;
  IsUnapproved: boolean;

  constructor(pageNumber: number, pageSize: number, orderBy: string, orderDirection: string, clientId?: string, clientGroupId?: string) {
    super(pageNumber, pageSize, orderBy, orderDirection, clientId, clientGroupId);
  }

  reset() {
    this.legalName = '';
    this.onlyWithSaldo = false;
    this.isPaused = false;
    this.IsUnapproved = false;
  }
}
