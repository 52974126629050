import { Pipe, PipeTransform } from '@angular/core';
import { Permission } from 'src/app/model';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {

  transform(permissions: Permission[]): string[] {
    return [Permission.SuperAdmin.toString(), ...permissions.map(x => x.toString())];
  }
}
