export enum InvoiceQuickStep {
  Send,
  DueDate,
  IncassoReminder,
  FirstReminder,
  Bailiff,
  SecondReminder,
  Registered,
  IncassoNotification,
  Notice
}
