import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard, NgxPermissionsModule } from 'ngx-permissions';
import { Permission } from './model';
import { AuthGuard } from './modules/auth/guards';
import { NotFoundComponent } from './modules/shared/components';


const routes: Routes = [
  { path: 'isabel', redirectTo: 'ext', pathMatch: 'prefix' },
  {
    path: 'ext',
    loadChildren: async () => (await import('./modules/external/external.module')).ExternalModule
  },
  {
    path: 'main',
    loadChildren: async () => (await import('./modules/main/main.module')).MainModule,
    canActivate: [AuthGuard],
    canActivateChild: [NgxPermissionsGuard]
  },
  {
    path: 'reset',
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: [Permission.SuperAdmin.toString()],
        redirectTo: '/notfound'
      }
    },
    loadChildren: async () => (await import('./modules/reset/reset.module')).ResetModule
  },
  { path: 'notfound', component: NotFoundComponent },
  { path: '', redirectTo: 'main/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgxPermissionsModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule { }
