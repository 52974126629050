import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GeneralModel } from './modules/store';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  @Select(state => state.general) general$: Observable<GeneralModel>;
  private generalStateSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    private title: Title) {
    translate.addLangs(['nl-be', 'fr-be', 'en-be']);
    // Set default here, setting chosen language should happen with PreferenceService (happens on login in AuthService)
    // External components should handle setting the language themselves
    translate.setDefaultLang('nl-be');

    this.generalStateSubscription = this.general$.subscribe((model: GeneralModel) => {
      if (!model || !model.pageTitle) {
        this.title.setTitle('Sales2Cash');
        return;
      }
      this.translate.get(model.pageTitle, model.titleParams).subscribe((result) => {
        this.title.setTitle('Sales2Cash | ' + result);
      });
    });
  }

  ngOnDestroy() {
    this.generalStateSubscription.unsubscribe();
  }
}
