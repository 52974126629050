import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceQuickState } from 'src/app/model';

@Pipe({
  name: 'invoiceQuickState'
})
export class InvoiceQuickStatePipe implements PipeTransform {

  transform(value: InvoiceQuickState, flowStateMappings: string[]): string {
    if ((!value && value !== 0) || flowStateMappings == undefined) {
      return '';
    }
    let state: string;
    switch (value as InvoiceQuickState) {
      case InvoiceQuickState.Normal:
        state = flowStateMappings[0];
        if (state === InvoiceQuickState[InvoiceQuickState.Normal]) {
          return `INVOICEQUICKSTATE.${state.toUpperCase()}`;
        } else {
          return state;
        }
      case InvoiceQuickState.Reminder:
        state = flowStateMappings[3];
        if (state === InvoiceQuickState[InvoiceQuickState.Reminder]) {
          return `INVOICEQUICKSTATE.${state.toUpperCase()}`;
        } else {
          return state;
        }
      case InvoiceQuickState.PaymentPlan:
        state = flowStateMappings[4].replace(" ", "");
        if (state === InvoiceQuickState[InvoiceQuickState.PaymentPlan]) {
          return `INVOICEQUICKSTATE.${state.toUpperCase()}`;
        } else {
          return state;
        }
      case InvoiceQuickState.SignedLetter:
        state = flowStateMappings[5].replace(" ", "");
        if (state === InvoiceQuickState[InvoiceQuickState.SignedLetter]) {
          return `INVOICEQUICKSTATE.${state.toUpperCase()}`;
        } else {
          return state;
        }
      case InvoiceQuickState.DebtCollection:
        state = flowStateMappings[6].replace(" ", "");
        if (state === InvoiceQuickState[InvoiceQuickState.DebtCollection]) {
          return `INVOICEQUICKSTATE.${state.toUpperCase()}`;
        } else {
          return state;
        }
      case InvoiceQuickState.JuridicalDebtCollection:
        state = flowStateMappings[8].replace(" ", "").replace(" ", "");
        if (state === InvoiceQuickState[InvoiceQuickState.JuridicalDebtCollection]) {
          return `INVOICEQUICKSTATE.${state.toUpperCase()}`;
        } else {
          return state;
        }
      default:
        // Get the actual enum name
        const invoiceQuickStateValue = InvoiceQuickState[value];
        return `INVOICEQUICKSTATE.${invoiceQuickStateValue.toUpperCase()}`;
    }
  }
}
