import { Pipe, PipeTransform } from '@angular/core';
import { DossierState } from 'src/app/model/enums/dossier-state';

@Pipe({
  name: 'dossierState'
})
export class DossierStatePipe implements PipeTransform {

  transform(value: DossierState): string {
    if (!value && value !== 0) {
      return '';
    }

    // Get the actual enum name
    const dossierStateValue = DossierState[value];
    return `DOSSIERSTATE.${dossierStateValue.toUpperCase()}`;
  }
}
