import {EventEmitter, Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {NgxPermissionsService} from 'ngx-permissions';
import {User, UserManager} from 'oidc-client';
import {BehaviorSubject, from, fromEventPattern, Observable} from 'rxjs';
import {distinctUntilChanged, mergeMap, take} from 'rxjs/operators';
import {ClientGroup, Permission, UserDetailDto} from 'src/app/model';
import {LivefeedService} from '../../shared/services';
import {PreferenceService} from '../../shared/services';
import {UserService} from '../../shared/services';
import {ClientModel, ClientState, ResetClient, SelectClient} from 'src/app/modules/store';

@Injectable()
export class AuthService {
  constructor(
    @Inject('windowObject') private window: Window,
    private userManager: UserManager,
    private router: Router,
    private userService: UserService,
    private permissionService: NgxPermissionsService,
    private preferenceService: PreferenceService,
    private livefeedService: LivefeedService,
    private store: Store) {
    this.setupSilentRenewal();
  }

  public onLogin: BehaviorSubject<UserDetailDto> = new BehaviorSubject<UserDetailDto>(undefined);
  public onLogout: EventEmitter<void> = new EventEmitter<void>();
  public accessToken: string;

  private user: User;
  private tokenChanges: Observable<User>;

  public async login(): Promise<void> {
    console.log('Attempting to initiate signinRedirect with state:', `${this.window.location.pathname}${this.window.location.search}`);

    this.userManager.signinRedirect({
      state: `${this.window.location.pathname}${this.window.location.search}`
    }).then(() => {
      console.log('SigninRedirect initiated successfully.');
    }).catch((error) => {
      console.error('SigninRedirect error:', error);
      console.log('Error details:', {
        errorName: error.name,
        errorMessage: error.message,
        errorStack: error.stack
      });
    });
  }

  public async logout() {
    await this.livefeedService.stop();
    await this.resetClientModel();
    this.userManager.signoutRedirect()
      .then(() => {
        this.onLogout.emit();
      })
      .catch((error) => console.error('Sign out error: ' + error));
  }

  public isAuthenticated(): boolean {
    return this.user && this.user.access_token && !this.user.expired;
  }

  public async doAuthenticationCheck(): Promise<void> {
    // If user is not loaded already, get from cache
    if (!this.user) {
      this.user = await this.userManager.getUser();
      if (this.isAuthenticated()) {
        // Trigger login success to get permissions
        this.user.state = `${this.window.location.pathname}${this.window.location.search}`;
        return this.onLoginSuccess(this.user);
      }
    }
    if (this.window.location.hash && this.window.location.hash.indexOf('#id_token=') === 0) {
      // if so, then we are in a sign in callback from Identity
      return this.userManager.signinRedirectCallback()
        .then((user: User) => {
          return this.onLoginSuccess(user, false, true);
        })
        .catch((e) => {
          // TODO: investigate why we get this message upon first login :(
          // Investigate here further: https://github.com/IdentityModel/oidc-client-js/issues/648
          // Temp fix
          if (e.message !== 'No matching state found in storage') {
            console.error(e.message);
            this.login();
          }
        });
    } else {
      // check if there is a currently authenticated user
      if (!this.isAuthenticated()) {
        return this.login();
      } else {
        // Already signed in
        return Promise.resolve();
      }
    }
  }

  private async onLoginSuccess(user: User, silent = false, loggingIn = false) {
    this.user = user;
    this.accessToken = user.access_token;

    await this.setClientModel();
    await this.livefeedService.start(this.accessToken);
    if (!silent) {
      try {
        const account = await this.userService.getCurrent();
        this.permissionService.loadPermissions(account.permissions.map(x => x.toString()));
        this.preferenceService.setPreferredLanguage(account.language)
          .pipe(take(1))
          .subscribe(async () => {
            this.onLogin.next(account);

            // Only clean up the URL when actually logging in, not on every authentication check
            if (loggingIn) {
              this.cleanupUrlAfterLogin();
            }

            const useRoute = this.user.state || this.extractRouteFromUrl();
            this.router.navigateByUrl(useRoute || '/');
          }, async () => await this.logout());
      } catch (error) {
        await this.logout();
        return;
      }
    }
  }

  private cleanupUrlAfterLogin(): void {
    const urlWithoutToken = this.window.location.href.split('#')[0];
    this.window.history.replaceState({}, document.title, urlWithoutToken);
  }

  private extractRouteFromUrl(): string {
    return this.window.location.href.replace(this.window.location.origin, '').split('#')[0];
  }

  private onLoginError(type: string, error: any) {
    console.error(error);
    throw new Error('AuthenticationManager > onLoginError: ' + type);
  }

  private setupSilentRenewal() {
    // create tokenChanges Observable
    this.tokenChanges = fromEventPattern(
      (handler) => {
        this.userManager.events.addAccessTokenExpiring(handler as any);
      },
      (handler) => {
        this.userManager.events.removeAccessTokenExpiring(handler as any);
      }
    ).pipe(
      mergeMap(() => from(this.userManager.signinSilent())),
      distinctUntilChanged()
    );

    // update user and token when changed
    this.tokenChanges.subscribe(
      (user) => this.onLoginSuccess(user, true),
      (error) => this.onLoginError('Silent renew', error)
    );
    this.userManager.events.addSilentRenewError(
      (error) => this.onLoginError('Silent renew', error)
    );
  }

  private async setClientModel() {
    const account = await this.userService.getCurrent();
    const groups = account.clientGroups;

    // Check if the previously stored client model needs a reset
    const needsReset = this.store.selectSnapshot(ClientState.needsReset(groups || []));
    if (needsReset) {
      await this.resetClientModelForUser(account, groups);
    }
  }

  private async resetClientModel() {
    // This means a SuperAdmin is logged in, when he logs out we want to make the clientModel undefined
    // So the next time someone logs in, we check again if the user is SuperAdmin or not
    const clientModel = this.store.selectSnapshot<ClientModel>(state => state.client);
    if (!clientModel.clientGroupIds && !clientModel.clientIds) {
      this.store.dispatch(new ResetClient());
    }
  }

  private async resetClientModelForUser(account: UserDetailDto, groups: ClientGroup[]) {
    // Check for superAdmin
    if (account.permissions.includes(Permission.SuperAdmin)) {
      this.store.dispatch(new SelectClient(new ClientModel(null, null)));
    } else {
      // If it is not a admin then reset the client
      this.store.dispatch(
        new SelectClient(
          new ClientModel([groups[0].id], groups[0].clients.length === 1 ? [groups[0].clients[0].id] : ['*'])
        ));
      return;
    }
  }
}
