import { UserManager } from 'oidc-client';
import { environment } from 'src/environments/environment';

export class OpenIdConnectFactory {
  public static createUserManager(): UserManager {
    const config: any = {
      authority: environment.authEndpoint,
      client_id: 'spaclient',
      redirect_uri: environment.appUri,
      post_logout_redirect_uri: environment.appUri,
      response_type: 'id_token token',
      scope: 'openid profile email satocaapi.access',
      silent_redirect_uri: environment.appUri + 'auth.html',
      silentRequestTimeout: 3000,
      automaticSilentRenew: true,
      loadUserInfo: true,
      clockSkew: 60, // 1 minute clock skew is allowed
      // acr_values: oidcConfig.acrValues,
      filterProtocolClaims: true
    };
    // if (oidcConfig.idp) {
    //     config.acr_values += ' idp:' + oidcConfig.idp;
    // }
    return new UserManager(config);
  }
}
