<div *ngIf="hasContacts">
  <app-contact-card [person]="topContact">
  </app-contact-card>
  <div *ngIf="!isCollapsed">
    <app-contact-card *ngFor="let otherContact of otherContacts" [person]="otherContact">
    </app-contact-card>
  </div>
  <div *ngIf="hasMoreContacts">
    <a href="javascript://" (click)="isCollapsed = !isCollapsed">
      {{ (isCollapsed ? 'DEBTOR.SHOWALLCONTACTS' : 'DEBTOR.COLLAPSECONTACTS') | translate}}
    </a>
  </div>
</div>
