import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-phonenumber-input',
  template: `
 <form class ="form-group formly"> 
 <intl-tel-input 
    #phone
    ngDefaultControl 
    [formControl]="formControl" 
    [cssClass]="formControl.invalid && (formControl.touched || formControl.dirty)  ? 'form-control is-invalid' : 'form-control'" 
    [options]="{ 
        initialCountry: 'be', 
        onlyCountries: ['be','nl','fr','de','es', 'it', 'ch'], 
        separateDialCode: true
    }" 
    [required]="false" 
    (input)="getPhoneData()"
    (countrychange)="getPhoneData()"
    ></intl-tel-input> 
   </form>
  `,
  styles: [
    '.form-group { margin-bottom: 0px; position: relative; }'
  ]
})

// tslint:disable-next-line: component-class-suffix
export class FormlyFieldPhoneNumberInput extends FieldType {
  @ViewChild('phone') phoneInput: any;

  ngAfterViewInit(): void{
    setTimeout(() => {
      const phoneNumber = this.formControl.value;
      if (phoneNumber && phoneNumber.number) {
        const intlTelInput = this.phoneInput.intlTelInput;
        intlTelInput.setNumber(phoneNumber.number);
        //display the error when retrieving the number and is not valid
        if (this.formControl.invalid) {
          intlTelInput.telInput.classList.add('is-invalid');
        }  
      }
    });
  }

getPhoneData(): void{
    const intlTelInput = this.phoneInput.intlTelInput;
    const countryCode = intlTelInput.getSelectedCountryData().iso2.toUpperCase();
    const dialCode = `+${intlTelInput.getSelectedCountryData().dialCode}`;
    const number = this.formControl.value?.number ?? this.formControl.value ?? null;
    const nodialCodeNumber = (number && typeof number === 'string' && number.startsWith('+')) ? number.substring(3) : number;
    const phoneData = { countryCode, dialCode, number: nodialCodeNumber };
    this.formControl.setValue(phoneData);
    intlTelInput.telInput.classList.toggle('is-invalid', !this.formControl.valid);   
  }


  constructor() {
    super();
  }
}
