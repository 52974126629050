import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';

export class Field {
  public static field(
    type: string,
    key: string,
    cssClass: string,
    templateOptions?: FormlyTemplateOptions,
    options?: any
  ): FormlyFieldConfig {
    return {
      type,
      key,
      className: cssClass,
      templateOptions,
      ...options
    };
  }

  public static input(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('input', key, cssClass, templateOptions, options);
  }

  public static mask(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('mask', key, cssClass, templateOptions, options);
  }

  public static autoComplete(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('autoComplete', key, cssClass, templateOptions, options);
  }

  public static phoneNumber(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('phoneNumber', key, cssClass, templateOptions, options);
  }

  public static date(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('date', key, cssClass, templateOptions, options);
  }

  public static email(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    const defaults = {
      type: 'email',
      label: 'Email'
    };

    return this.input(key, cssClass, { ...templateOptions, ...defaults }, options);
  }

  public static password(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    const defaults = {
      label: 'Password',
      type: 'password',
      required: true
    };

    return this.input(key, cssClass, { ...templateOptions, ...defaults }, options);
  }

  public static select(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('select', key, cssClass, templateOptions, options);
  }

  public static textarea(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('textarea', key, cssClass, templateOptions, options);
  }

  public static radio(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('radio', key, cssClass, templateOptions, options);
  }

  public static checkbox(key: string, cssClass: string, templateOptions?: FormlyTemplateOptions, options?: any): FormlyFieldConfig {
    return this.field('checkbox', key, cssClass, templateOptions, options);
  }
}
