import { ClientModel } from './client.model';

export class SelectClient {
  static readonly type = '[Client] Select Client';
  constructor(public clientModel: ClientModel) {}
}

export class ResetClient {
  static readonly type = '[Client] Reset Client';
  constructor() {}
}
