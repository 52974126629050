import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneNumberDto } from 'src/app/model';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phoneNumber: PhoneNumberDto): string {
    // Check for empty string or null
    if (!phoneNumber || !phoneNumber.number) {
      return '';
    }
    // Try to parse else show just the given number
    try {
      // Parse to an object of the lib, so the lib can format
      const numberToFormat = phoneNumberUtil.parse(phoneNumber.number);
      // Format to the international standard
      return phoneNumberUtil.format(numberToFormat, PhoneNumberFormat.INTERNATIONAL);
    } catch {
      return phoneNumber.number;
    }
  }
}
