import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContactPersonDto, PersonRole} from '../../../../model';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() person: ContactPersonDto;
  @Input() clickable: boolean;
  @Output() personClicked: EventEmitter<ContactPersonDto>;
  isCollapsed: boolean;
  showRoleInfo: boolean;

  constructor() {
    this.isCollapsed = true;
    this.personClicked = new EventEmitter<ContactPersonDto>();
  }

  onPersonClicked(){
    if (this.clickable){
      this.personClicked.emit(this.person);
    }
  }

  ngOnInit(): void {
    // Don't show the contact person's role when it's a 'normal' person
    this.showRoleInfo = this.person.role != PersonRole.Normal;
  }
}
