import { Component, Input } from '@angular/core';
import { PaymentPlan, PaymentPlanInterval, PaymentPlanSelection } from 'src/app/model';

@Component({
  selector: 'app-paymentplan-simulation',
  templateUrl: './paymentplan-simulation.component.html',
  styleUrls: ['./paymentplan-simulation.component.scss']
})
export class PaymentplanSimulationComponent {
  @Input() selection: PaymentPlanSelection;
  @Input() suggestion: PaymentPlan;
  intervalEnum: any = PaymentPlanInterval;
  constructor() { }
}
