import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { NotificationDto, SignalType } from 'src/app/model';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../main/services/notification.service';

@Injectable({ providedIn: 'root' })
export class LivefeedService {
  public connection: HubConnection;
  watchedEntities: string[];
  subscribedEntities: string[];

  constructor(private notificationService: NotificationService) {
    this.watchedEntities = [];
    this.subscribedEntities = [];
  }

  isConnected = () => this.connection && this.connection.state === 'Connected';

  public async start(accessToken?: string) {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${ environment.signalREndpoint }`, { accessTokenFactory: () => accessToken })
      .withAutomaticReconnect()
      .build();
    this.connection.start().catch(err => console.log(err.message + ' ' + 'livefeed start'));

    // Listen for new changes for notifications
    this.connection.on(SignalType.NewNotification, (notification: NotificationDto) => {
      this.notificationService.setNewNotification(notification);
      this.notificationService.setNotifications();
    });
  }

  public async stop() {
    await this.connection.stop().catch(err => console.log(err + ' ' + 'livefeed stop'));
  }

  public async watchEntity(entityId: string, callback: any) {
    if (!entityId) {
      return;
    }
    this.watchedEntities.push(entityId);

    setTimeout(async () => {
      // It could be that entity isn't watched anymore
      if (this.watchedEntities.includes(entityId) && this.isConnected()) {
        // Create a group for this entity id (if it doesn't exist already) and add this connection to the group
        await this.connection.invoke('watchEntity', entityId);

        // Listen for changes pushed to this group
        this.connection.on(`updated-${ entityId }`, callback);

        this.subscribedEntities.push(entityId);
      }
    }, 7500);
  }

  public async stopWatchingEntity(entityId: string, callback: any) {
    if (!entityId) {
      return;
    }

    this.watchedEntities = this.watchedEntities.filter(x => x !== entityId);

    if (this.subscribedEntities.includes(entityId)) {
      await this.connection.invoke('stopWatchingEntity', entityId);
      this.connection.off(`updated-${ entityId }`, callback);
      this.subscribedEntities = this.subscribedEntities.filter(x => x !== entityId);
    }
  }
}
