export enum Permission {
  // Permissions concerning the client
  Client_HasClientAccess = 0,             // Allows access to client
  Client_CanViewDebtorDashboard = 1,      // Allows to see the debtor dashboard
  Client_CanViewFinancialDashboard = 2,   // Allows to see the financial dashboard
  Client_CanViewUserManagement = 3,       // Allows access to user management, and create/update users
  Client_CanUpdateInvoiceAmount = 4,      // Allows to write off an invoice, and register manual payments
  Client_CanWriteOffRemaining = 5,        // Allows to write off remaining amount
  Client_CanStopAction = 6,               // Allows to stop tracking an invoice
  Client_Admin = 7,                       // Allows for instance creation of new clients in an existing ClientGroup
  Client_CanUseGraydon = 8,               // Allows usage of Graydon for this user
  Client_CanUseVision2Pay = 9,            // Allows usage in Vision2Pay module (V&B)
  Client_CanViewPayments = 10,            // Allows usage of payments
  Client_CanViewDebtors = 11,             // Allows access to debtors
  Client_CanViewInvoices = 12,            // Allows access to invoices
  Client_CanViewActions = 13,             // Allows access to actions
  Client_CanViewNotifications = 14,       // Allows access to notifications
  Client_CanPerformGlobalSearch = 15,     // Allows performing a global search (regardless of the type of return data!)
  Client_CanPause = 16,                   // Allows pausing of debtors and actions
  Client_CanViewBillableReports = 17,     // Allows access to reports
  Client_CanViewInvoicePaymentReports = 18, // Allows access to reports
  Client_CanViewImports = 19,              // Allow access to imports
  Client_CanUseSales2CashImport = 20,     // Can import ubls and debtors without putting them in special flow (=Sales2Cash)
  Client_CanViewPaymentplans = 21,       // Allows access to paymentplans
  Client_CanViewClientSettings = 22,       // Allows access to clientsettings
  Client_CanViewSales2CashSchoolImport = 23,  // Can import excel invoices and debtors without putting them in special flow (=Sales2Cash)
  Client_CanViewSales2CashTeamLeaderImport = 24,  // Can import invoices and debtors from TeamLeader without putting them in special flow (=Sales2Cash)
  Client_CanViewSales2CashOctopusImport = 25,  // Can import invoices and debtors from Octopus without putting them in special flow (=Sales2Cash)
  Client_CanRemovePayment = 26, // Can remove payments in timeline
  Client_CanViewSales2CashYukiImport = 27,
  Client_CanViewFtpImports = 28, // Can view FTP imports tab
  Client_CanAddNewClient = 29,
  Client_IsReadOnly = 30, // Can add/update invoice on invoice-tab in the actions section
  // Permissions concerning API calls
  // Api_Something = 100

  // Super Admin permission
  SuperAdmin = 666                        // Allows for viewing all clientgroups
}
