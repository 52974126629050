import {Pipe, PipeTransform} from '@angular/core';
import {Country} from '../../../../model/enums';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  transform(value: Country): string {
    if (!value && value !== 0) {
      return '';
    }

    // Get the actual corresponding enum
    const countryEnumValue = Country[value];

    return `COUNTRY.${countryEnumValue.toUpperCase()}`;
  }
}
