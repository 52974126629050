import { ClientFilterModel } from './client-filter.model';

export class SearchFilter extends ClientFilterModel {
  valueToSearch: string;
  onPropKey: string;

  constructor(valueToSearch: string, onPropKey: string, clientId?: string, clientGroupId?: string) {
    super(clientId, clientGroupId);
    this.valueToSearch = valueToSearch;
    this.onPropKey = onPropKey;
  }

  reset() {
    this.valueToSearch = undefined;
    this.onPropKey = undefined;
  }
}
