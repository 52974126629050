export enum Country {
  Belgium,
  Netherlands,
  France,
  Germany,
  Spain,
  Italy,
  Luxembourg,
  Switzerland
}
