import { Pipe, PipeTransform } from '@angular/core';
import { Language } from 'src/app/model';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value: Language): string {
    if (!value && value !== 0) {
      return 'LANGUAGE.NULL';
    }

    // Get the actual enum name
    const languageValue = Language[value];

    return `LANGUAGE.${languageValue.toUpperCase()}`;
  }
}
