export enum NotificationType {
  General,
  Protest,
  DebtorImport,
  InvoiceImport,
  PaymentReportImport,
  CtpActionFailing,
  PlannedActionIsPaused,
  Support,
  ReminderNote,
  TeamLeaderDebtorImport,
  TeamLeaderInvoiceImport,
  OctopusDebtorImport,
  OctopusInvoiceImport,
  YukiDebtorImport,
  YukiInvoiceImport,
  ClientAdmin,
}
