import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // For non relative path urls or asset calls
    // we assume it's not an api call and we don't append the base api endpoint
    if (req.url.startsWith('http') || req.url.startsWith('/assets')) {
      return next.handle(req);
    }

    let apiReq: HttpRequest<any>;
    apiReq = req.clone(
      {
        url: `${environment.apiEndpoint}/${req.url}`,
        headers: this.addExtraHeaders(req.headers, 'Bearer ' + this.authService.accessToken)
      });

    return next.handle(apiReq).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          console.log('---> status:', evt.status);
          console.log('---> url:', apiReq.url);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error from HTTP request', error);
        return throwError(error);
      })
    );
  }

  private addExtraHeaders(headers: HttpHeaders, tokenValue: string): HttpHeaders {
    // When the user has been logged in and a token is present, we add it to the header obviously
    if (tokenValue) {
      headers = headers.append('Authorization', tokenValue);
    }
    return headers;
  }
}
