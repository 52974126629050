import { Pipe, PipeTransform } from '@angular/core';
import { VatNumberDto } from 'src/app/model/dtos/client/vat-number.dto';
import { Country } from 'src/app/model/enums/country.enum';

@Pipe({
  name: 'vatNumber'
})
export class VatNumberPipe implements PipeTransform {

  transform(vatNumber: VatNumberDto): string {
    if (!vatNumber?.value) {
      return '';
    }

    switch (vatNumber.country) {
      case Country.Belgium:
        if (vatNumber.value.toUpperCase().includes('BE')) {
          return `${vatNumber.value.substring(0, 2)} ${vatNumber.value.substring(2, 6)} ${vatNumber.value.substring(6, 9)} ${vatNumber.value.substring(9, 12)}`;
        } else {
          return `BE ${vatNumber.value.substring(0, 4)} ${vatNumber.value.substring(4, 7)} ${vatNumber.value.substring(7, 10)}`;
        }
      case Country.Netherlands:
        if (vatNumber.value.toUpperCase().includes('NL')) {
          return `${vatNumber.value.substring(0, 2)} ${vatNumber.value.substring(2, 14)}`;
        } else {
          return `NL ${vatNumber.value.substring(0, 12)}`;
        }
      case Country.France:
        if (vatNumber.value.toUpperCase().includes('FR')) {
          return `${vatNumber.value.substring(0, 2)} ${vatNumber.value.substring(2, 4)} ${vatNumber.value.substring(4, 15)}`;
        } else {
          return `FR ${vatNumber.value.substring(0, 2)} ${vatNumber.value.substring(2, 13)}`;
        }
      default:
        return `${vatNumber.value.substring(0, (vatNumber.value.length))}`;
    }
  }
}
