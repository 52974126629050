import { CompanyType, Country, Language } from '../../enums';
import { PhoneNumberDto } from './phone-number.dto';
import { VatNumberDto } from './vat-number.dto';

export class CreateVision2PayDto {
  name: string;
  companyType: CompanyType;
  language: Language;
  firstName: string;
  lastName: string;
  vatNumber: VatNumberDto;
  accountNumber: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  place: string;
  country: Country;
  email: string;
  phone: PhoneNumberDto;
  password: string;
  b2BInterestPercentage: number;
  b2CInterestPercentage: number;
  fine: number;
  percentagefine: number;
  fineIsPercentage: boolean;
  minimumFine: number;
  maximumFine: number;
  skipFlowCreation?: boolean;
  currentClientGroupId?: string;
  agreedToTerms: boolean;

  constructor(currentLanguage, firstName, lastName, email, skipFlowCreation, currentClientGroupId) {
    this.name = '';
    this.companyType = CompanyType.None;
    this.language = currentLanguage;
    this.firstName = firstName;
    this.lastName = lastName;
    this.vatNumber = null;
    this.accountNumber = '';
    this.street = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.place = '';
    this.country = Country.Belgium;
    this.email = email;
    this.phone = null;
    this.password = '';
    this.b2BInterestPercentage = 0;
    this.b2CInterestPercentage = 0;
    this.fine = 40;
    this.percentagefine = 0;
    this.fineIsPercentage = true;
    this.minimumFine = 40;
    this.maximumFine = null;
    this.agreedToTerms = false;
    this.skipFlowCreation = skipFlowCreation;
    this.currentClientGroupId = currentClientGroupId;
  }

  prepareDto(): CreateVision2PayDto {
    const duplicate = { ...this };
    duplicate.fine = duplicate.fineIsPercentage ? duplicate.percentagefine : duplicate.fine;
    duplicate.minimumFine = duplicate.fineIsPercentage ? duplicate.minimumFine : null;
    duplicate.maximumFine = duplicate.fineIsPercentage ? duplicate.maximumFine : null;
    return duplicate;
  }
}
