import { Pipe, PipeTransform } from '@angular/core';
import { PersonRole } from '../../../../model';

@Pipe({
        name: 'personRole'
      })
export class PersonRolePipe implements PipeTransform {

  transform(value: PersonRole): string {
    // Get the actual enum name
    const nameOfPersonRoleEnum = PersonRole[value];
    if (!nameOfPersonRoleEnum) {
      return '';
    }
    return `PERSONROLE.${ nameOfPersonRoleEnum.toUpperCase() }`;
  }
}
