import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CustomDatePickerService } from './../../../modules/shared/services/custom-datepicker.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-date-input',
  template: `
  <div class="form-group formly" [formGroup]="form">
    <p-calendar inputStyleClass="form-control" [formControlName]="field.key" appendTo="body"
      [placeholder]="to.placeholder" [minDate]="to.mustBeAfter" [maxDate]="to.mustBeBefore"
      dateFormat="d/mm/yy" [locale]="locale" showButtonBar="true" selectOtherMonths="true">
    </p-calendar>
  </div>
  `,
  styles: [
    '.form-group { margin-bottom: 0px; position: relative; }'
  ]
})
// tslint:disable-next-line: component-class-suffix
export class FormlyFieldDateInput extends FieldType {
  get type() {
    return this.to.type || 'text';
  }

  locale: any;
  constructor(private customDatePickerService: CustomDatePickerService) {
    super();
    this.locale = this.customDatePickerService.locale;
  }
}
