<div class="row py-2 mb-3 rounded shadow-sm wrapper">
  <div class="col">
    <div class="row">
      <div class="col-11" [class.clickable]="clickable" (click)="onPersonClicked()">
        <!-- Summary info-->
        <div class="row">
          <div class="col">
            {{ person.firstName }} {{ person.lastName}}
            <span *ngIf="person.birthDate" class="light-small-text"> ({{person.birthDate | date:'dd/MM/yyyy'}}) </span>
            <fa-icon *ngIf="!person.isValid" class="pl-2 text-warning" [icon]="['fas', 'exclamation-triangle']"
                     pTooltip="{{'DEBTOR.INVALID' | translate}}"></fa-icon>
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{ person.mailAddress }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>{{ person.phoneNumber | phoneNumber }}</span>
          </div>
        </div>
      </div>
      <!-- Collapse icon-->
      <div class="col-1 d-flex align-items-center">
        <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="isCollapsed" class="clickable"
                 (click)="isCollapsed = false"></fa-icon>
        <fa-icon [icon]="['fas', 'chevron-up']" *ngIf="!isCollapsed" class="clickable"
                 (click)="isCollapsed = true"></fa-icon>
      </div>
    </div>
    <!-- Address info -->
    <div *ngIf="!isCollapsed">
      <div class="row">
        <div class="col">
          {{ person.address?.street }} {{ person.address?.houseNumber }}
        </div>
      </div>
      <div class="row" *ngIf="person.address?.postalCode || person.address?.town">
        <div class="col">
          {{ person.address?.postalCode }}<ng-container *ngIf="person.address?.town">, {{ person.address?.town }}</ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{ person.address?.country | country | translate }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{ 'GENERAL.LANGUAGE' | translate }}: {{ person.preferredLanguage | language | translate }}
        </div>
      </div>
    </div>
    <!-- Role info -->
    <div class="row" *ngIf="showRoleInfo">
      <div class="col light-small-text">
        {{ person.role | personRole | translate }}
      </div>
    </div>
  </div>
</div>
