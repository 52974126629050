export enum InvoiceQuickState {
  Normal,
  Reminder,
  PaymentPlan,
  SignedLetter,
  DebtCollection,
  Closed,
  Paused,
  Protested,
  JuridicalDebtCollection
}
