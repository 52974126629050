import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash-es';
import { Country, GraydonAnnualAccountsDto, GraydonCompanyInformationDto, GraydonCompanySearchResultDto, GraydonCompanySuggestionDto, SearchFilter } from 'src/app/model';

@Injectable()
export class GraydonService {
  private graydonUrl = 'graydon';
  constructor(private http: HttpClient) { }

  public async getCompanies(
    companyName: string,
    companyNumber: string,
    country: Country,
    skip: number,
    take: number): Promise<GraydonCompanySearchResultDto> {
    return await this.http.get<GraydonCompanySearchResultDto>(`${this.graydonUrl}/search`,
      { params: { companyName, companyNumber, country, skip, take } as any }).toPromise();
  }

  public async getCompanyInformation(country: Country, graydonEnterpriseId: number): Promise<GraydonCompanyInformationDto> {
    return await this.http.get<GraydonCompanyInformationDto>(`${this.graydonUrl}/companyinformation`,
      { params: { country, graydonEnterpriseId } as any }).toPromise();
  }

  public async getAnnualAccounts(country: Country, graydonEnterpriseId: number): Promise<GraydonAnnualAccountsDto> {
    return await this.http.get<GraydonAnnualAccountsDto>(`${this.graydonUrl}/annualaccounts`,
    { params: { country, graydonEnterpriseId } as any }).toPromise();
  }

  public async getCompanySuggestions(filter: SearchFilter): Promise<GraydonCompanySuggestionDto[]> {
    filter = omitBy(filter, isNil);
    return await this.http.get<GraydonCompanySuggestionDto[]>(`${this.graydonUrl}/suggest`,
      { params: filter as any }).toPromise();
  }
}
