<p-dialog [header]="'GENERAL.LINK_DEBTOR' | translate" styleClass="custom-dialog dialog-vw-50" [modal]="true"
          [baseZIndex]="10000"
          [closeOnEscape]="false" [closable]="true" [(visible)]="visible" [draggable]="false" [resizable]="false"
          (onHide)="close()">
  <div class="col">
    <div class="d-flex flex-row align-items-center px-5">
      <fa-icon class="d-inline-block" [icon]="['fas', 'search']"></fa-icon>
      <input class="mx-2 form-control d-inline-block" type="text" (input)="updateSearchQuery($event.target.value)"
             [placeholder]="'GENERAL.SEARCH' | translate">
    </div>
    <div class="px-4">
      <p-table [value]="pagedDebtors.debtors" styleClass="custom-table" [autoLayout]="true" responsiveLayout="stack" breakpoint="680px"
               (onRowSelect)="onDebtorSelect($event.data)" [lazy]="true" (onLazyLoad)="tableLazyLoad($event)"
               [paginator]="true" selectionMode="single" [(selection)]="selectedDebtorId" [first]="currentFilter.skip"
               [rows]="currentFilter.take" [totalRecords]="pagedDebtors.totalCount" *ngIf="pagedDebtors">
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{'GENERAL.DEBTOR' | translate}}
            </th>
            <th>
              {{'VISION2PAY.ADDINVOICES.VATNUMBER' | translate}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-debtor>
          <tr *ngIf="debtor.isValid" [pSelectableRow]="debtor.id " class="clickable">
            <td>
              {{debtor.name}}
            </td>
            <td>
              <span *ngIf="debtor.companyType">{{debtor.vatNumber | vatNumber}}</span>
            </td>
          </tr>
          <tr *ngIf="!debtor.isValid" class="text-secondary">
            <td>
              {{debtor.name}}
              <fa-icon class="pl-2 text-warning" [icon]="['fas', 'exclamation-triangle']"
                       pTooltip="{{'DEBTOR.INVALID' | translate}}">

              </fa-icon>
            </td>
            <td>
              <span *ngIf="debtor.companyType">{{debtor.vatNumber | vatNumber}}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="2" class="text-center" translate>
              GENERAL.NORECORDS
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer class="d-block">
    <button [disabled]="isLinking || !selectedDebtorId" type="button" class="btn btn-primary ml-3 float-right"
            (click)="linkDebtorToDossier()">
      <span translate>GENERAL.LINK_DEBTOR</span>
    </button>
    <button type="button" class="btn btn-secondary float-right" data-dismiss="modal"
            (click)="close()">{{'GENERAL.CANCEL' | translate}}</button>
  </p-footer>
</p-dialog>
