<div class="row bg-white my-3 p-4 rounded shadow-sm" *ngIf="paymentPlan">
  <div class="col">
    <div class="row my-3">
      <div class="col">
        <span class="text-uppercase light-small-text" translate>PAYMENTPLANDETAIL.TITLE</span>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" *ngIf="!alwaysShowPaymentTable" (click)="showPaymentTable = !showPaymentTable">
          <span *ngIf="!showPaymentTable" translate>PAYMENTPLANDETAIL.SHOWPAYMENTTABLE</span>
          <span *ngIf="showPaymentTable" translate>PAYMENTPLANDETAIL.HIDEPAYMENTTABLE</span>
        </button>
        <div class="float-right ml-2" *ngIf="!inCreation">
          <div *ngxPermissionsOnly="[Permission.Client_Admin, Permission.SuperAdmin] | permission">
            <button *ngIf="!deleting" class="btn btn-danger" (click)="deletePaymentPlanDialog.show()">
              <span translate>PAYMENTPLANDETAIL.REMOVE_PAYMENTPLAN</span>
            </button>
            <button *ngIf="deleting" class="btn btn-danger" disabled type="button"><span class="spinner-border spinner-border-sm" role="status"></span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto my-2">
        <div class="row">
          <div class="col">
            <span class="d-inline-block">{{ 'PAYMENTPLANDETAIL.TOTALAMOUNT' | translate }}:&nbsp;</span>
            <span class="d-inline-block">{{ paymentPlan.totalToBePaidAmount | currencyFormat }}</span>
          </div>
        </div>
        <div class="row" *ngIf="!inCreation">
          <div class="col">
            <span class="d-inline-block">{{ 'PAYMENTPLANDETAIL.PAIDAMOUNT' | translate }}:&nbsp;</span>
            <span class="d-inline-block">{{ paymentPlan.totalPaidAmount | currencyFormat }}</span>
          </div>
        </div>
      </div>
      <div class="col-auto my-2">
        <div class="row">
          <div class="col">
            <fa-icon class="mr-2" [icon]="['far', 'calendar-alt']"></fa-icon>
            <span class="d-inline-block">{{ 'PAYMENTPLANDETAIL.PAYMENTINTERVAL' | translate }}:&nbsp;</span>
            <span *ngIf="paymentPlan.paymentInterval === PaymentInterval.Weekly" class="d-inline-block">{{ 'PAYMENTPLANDETAIL.WEEKLY' | translate }}</span>
            <span *ngIf="paymentPlan.paymentInterval === PaymentInterval.Monthly" class="d-inline-block">{{ 'PAYMENTPLANDETAIL.MONTHLY' | translate }}</span>
          </div>
        </div>
        <div class="row" *ngIf="!inCreation && paymentPlan.mandateInfo">
          <div class="col">
            <fa-icon class="mr-2" [icon]="['fas', 'file-signature']"></fa-icon>
            <span class="d-inline-block">{{ 'PAYMENTPLANDETAIL.ISSIGNED' | translate }}:&nbsp;</span>
            <div class="tag ml-2 d-inline-block" [class.green]="paymentPlan.mandateInfo.mandateIsSigned"
              [class.red]="!paymentPlan.mandateInfo.mandateIsSigned">
              <span *ngIf="paymentPlan.mandateInfo.mandateIsSigned">{{'PAYMENTPLANDETAIL.YES' | translate}}</span>
              <span *ngIf="!paymentPlan.mandateInfo.mandateIsSigned">{{'PAYMENTPLANDETAIL.NO' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!inCreation && paymentPlan.mandateInfo && paymentPlan.mandateInfo.mandateIsSigned">
          <div class="col">
            <fa-icon class="mr-2" [icon]="['fas', 'file-signature']"></fa-icon>
            <span class="d-inline-block">{{ 'PAYMENTPLANDETAIL.DATESIGNED' | translate }}:&nbsp;</span>
            <span class="d-inline-block">{{ paymentPlan.mandateInfo.signDate | amLocale:'nl-be' | amDateFormat:'L' }}</span>
          </div>
        </div>
      </div>
      <div class="col-auto my-2" *ngIf="paymentPlan.mandateInfo.mandateIsSigned">
        <div class="row">
          <div class="col">
            <a class="btn btn-success text-white" [href]="getSignedPaymentPlanUrl(paymentPlan.id)" target="_blank">{{ 'PAYMENTPLAN.SUCCES.SIGNED.DOWNLOAD' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="showPaymentTable || alwaysShowPaymentTable">
      <div class="col">
        <p-table [autoLayout]="true" [value]="paymentPlan.items" styleClass="custom-table mb-0">
          <ng-template pTemplate="header">
            <tr>
              <th translate>
                PAYMENTPLANDETAIL.TABLE.DATE
              </th>
              <th translate>
                PAYMENTPLANDETAIL.TABLE.REMAINING
              </th>
              <th translate>
                PAYMENTPLANDETAIL.TABLE.CAPITAL
              </th>
              <th translate>
                PAYMENTPLANDETAIL.TABLE.INTREST
              </th>
              <th translate>
                PAYMENTPLANDETAIL.TABLE.COSTS
              </th>
              <th [class.font-weight-bold]="inCreation" translate>
                PAYMENTPLANDETAIL.TABLE.AMOUNT
              </th>
              <th *ngIf="!inCreation" translate>
                PAYMENTPLANDETAIL.TABLE.AMOUNTPAID
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr [class.danger]="item.paymentDueDateHasPassed && item.amount.amountToBePaid > item.amount.amountPaid">
              <td>
                {{item.paymentDueDate | amLocale:'nl-be' | amDateFormat:'L'}}
              </td>
              <td>
                <div class="currency">
                  {{item.amount.dossierRemainingTotal | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency">
                  {{item.amount.invoiceAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency">
                  {{item.amount.interestAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency">
                  {{item.amount.costsAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency" [class.font-weight-bold]="inCreation">
                  {{item.amount.amountToBePaid | currencyFormat}}
                </div>
              </td>
              <td *ngIf="!inCreation">
                <div class="currency">
                  {{item.amount.amountPaid | currencyFormat}}
                </div>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr class="font-weight-bold">
              <td>
                {{'PAYMENTPLANDETAIL.TABLE.TOTAL' | translate}}
              </td>
              <td>
                <div class="currency">
                  {{paymentPlan.totalAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency">
                  {{paymentPlan.totalInvoiceAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency">
                  {{paymentPlan.totalInterestAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency">
                  {{paymentPlan.totalCostsAmount | currencyFormat}}
                </div>
              </td>
              <td>
                <div class="currency" [class.font-weight-bold]="inCreation">
                  {{paymentPlan.totalToBePaidAmount | currencyFormat}}
                </div>
              </td>
              <td *ngIf="!inCreation">
                <div class="currency">
                  {{paymentPlan.totalPaidAmount | currencyFormat}}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- delete paymentplan dialog -->
<app-confirm-dialog #deletePaymentPlanDialog [header]="'GENERAL.CONFIRM' | translate" [isWithNote]="true"
  (approved)="deletePaymentPlan($event)" [dialogText]="'PAYMENTPLAN.CONFIRM_DELETE' | translate"></app-confirm-dialog>
