import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SetPlannedActions } from './planned-action.actions';
import { PlannedActionModel } from './planned-action.model';

@State<PlannedActionModel>({
  name: 'plannedAction',
  defaults: {
    plannedActions: [],
    numberOfActionsComingWeek: 0
  }
})
@Injectable()
export class PlannedActionState {
  @Action(SetPlannedActions)
  setPlannedActions(ctx: StateContext<PlannedActionModel>, action: SetPlannedActions) {
    const currState = ctx.getState();
    ctx.setState({
      ...currState,
      plannedActions: action.plannedActions,
      numberOfActionsComingWeek: action.numberOfActionsComingWeek
    });
  }
}
