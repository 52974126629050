import { Pipe, PipeTransform } from '@angular/core';
import {InvoiceQuickStep} from "../../../../model/enums/invoice-quick-step";

@Pipe({
  name: 'invoiceQuickStep'
})
export class InvoiceQuickStepPipe implements PipeTransform {

  transform(value: InvoiceQuickStep): string {
    if ((!value && value !== 0)) {
      return '';
    }

    const invoiceQuickStateValue = InvoiceQuickStep[value];
    return `INVOICEQUICKSTEP.${invoiceQuickStateValue.toUpperCase()}`;
  }
}
