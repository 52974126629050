import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: number,
            placeholder: string = '',
            currencySign: string = '€ ',
            decimalLength: number = 2,
            chunkDelimiter: string = '.',
            decimalDelimiter: string = ',',
            chunkLength: number = 3): string {

    // https://medium.com/@basarat/null-vs-undefined-in-typescript-land-dc0c7a5f240a
    // tslint:disable-next-line:triple-equals
    if (value == undefined || value == null) {
      return placeholder;
    }

    const result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    const num = value.toFixed(Math.max(0, decimalLength));

    return currencySign + (decimalDelimiter ? num
      .replace('.', decimalDelimiter) : num)
      .replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
  }

}
