<div class="row mt-4 bg-white p-4 rounded shadow-sm">
  <div class="col">
    <div>
      {{
        'PAYMENTPLAN.SIMULATION_EXPLANATION' | translate: {
          intervalText: (selection.interval === intervalEnum.Weekly? 'PAYMENTPLAN.WEEKLY' : 'PAYMENTPLAN.MONTHLY') | translate | lowercase,
          payAmount: suggestion.items[0].amount.amountToBePaid | currencyFormat,
          startDate: suggestion.items[0].paymentDueDate | amLocale:'nl-be' | amDateFormat:'L'
        }
      }}
    </div>
    <div *ngIf="suggestion.items[0].amount.amountToBePaid !== suggestion.items[suggestion.items.length - 1].amount.amountToBePaid" class="text-danger">
        {{
          'PAYMENTPLAN.ATTENTION' | translate: {
            payAmount: suggestion.items[suggestion.items.length - 1].amount.amountToBePaid | currencyFormat,
            payDate: suggestion.items[suggestion.items.length - 1].paymentDueDate | amLocale:'nl-be' | amDateFormat:'L'
          }
        }}
    </div>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <app-payment-plan-detail *ngIf="suggestion" [paymentPlan]="suggestion" [alwaysShowPaymentTable]="true" [inCreation]="true"></app-payment-plan-detail>
  </div>
</div>
