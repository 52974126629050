import { InvoiceQuickState, PlannedActionState } from '../enums';
import { PagedFilterModel } from './paged-filter.model';

export enum PlannedActionType {
  Invoice,
  PaymentPlan
}

export class PlannedActionFilter extends PagedFilterModel {
  fromDate: Date;
  toDate: Date;
  withCosts: boolean;
  searchQuery: string;
  state: PlannedActionState;
  invoiceState: InvoiceQuickState;
  type: PlannedActionType;
  actionDescription: string;
  actionClientId: string;
  actionId: string;

  constructor(pageNumber: number, pageSize: number, orderBy: string, orderDirection: string, clientId?: string, clientGroupId?: string) {
    super(pageNumber, pageSize, orderBy, orderDirection, clientId, clientGroupId);
  }

  reset() {
    this.fromDate = undefined;
    this.toDate = undefined;
    this.withCosts = undefined;
    this.searchQuery = '';
    this.actionDescription = '';
    this.actionClientId = undefined;
    this.actionId = undefined;
    this.invoiceState = undefined;
  }
}
