import { ConfigOption } from '@ngx-formly/core';
import { FormlyFieldAutoCompleteInput } from './helpers/autocomplete-input.component';
import { FormlyFieldDateInput } from './helpers/date-input.component';
import { FormlyFieldMaskInput } from './helpers/mask-input.component';
import { FormlyFieldPhoneNumberInput } from './helpers/phonenumber-input.component';
import { RepeatTypeComponent } from './helpers/repeat-section.type';
// tslint:disable-next-line:max-line-length

export const config: ConfigOption = {
  types: [
    { name: 'mask', component: FormlyFieldMaskInput, wrappers: ['form-field'] },
    { name: 'date', component: FormlyFieldDateInput, wrappers: ['form-field'] },
    { name: 'autoComplete', component: FormlyFieldAutoCompleteInput, wrappers: ['form-field'] },
    { name: 'phoneNumber', component: FormlyFieldPhoneNumberInput, wrappers: ['form-field'] },
    { name: 'repeat', component: RepeatTypeComponent },
  ]
};
