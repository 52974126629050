import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateRoleModel, PagedFilterModel, PagedUserModel, UserDetailDto, UserDto, UserEntryModel, UserRoleDto } from 'src/app/model';
import { SharedModule } from '../shared.module';
import { QueryStateService } from './query-state.service';

@Injectable()
export class UserService {
  private usersUrl = 'users';
  constructor(
    private http: HttpClient,
    private queryStateService: QueryStateService) {
  }

  async getUsers(filter: PagedFilterModel): Promise<PagedUserModel> {
    const params = { ...this.queryStateService.addClientParameters(filter) } as any;
    return this.http.get<PagedUserModel>(`${this.usersUrl}`, { params }).toPromise();
  }

  async getUserDetail(id: string): Promise<UserDetailDto> {
    const params = { ...this.queryStateService.addClientParameters({}) } as any;
    return this.http.get<UserDetailDto>(`${this.usersUrl}/${id}`, { params }).toPromise();
  }

  async getRoleDefinitions(): Promise<UserRoleDto[]> {
    const params = { ...this.queryStateService.addClientParameters({}) } as any;
    return this.http.get<UserRoleDto[]>(`${this.usersUrl}/roles`, { params }).toPromise();
  }

  async getCurrent(): Promise<UserDetailDto> {
    return this.http.get<UserDetailDto>(`${this.usersUrl}/current`).toPromise();
  }

  async create(user: UserEntryModel): Promise<void> {
    const params = this.queryStateService.addClientParameters(user) as any;
    return this.http.post<void>(`${this.usersUrl}`, params).toPromise();
  }

  async update(user: UserDto): Promise<void> {
    return this.http.patch<void>(`${this.usersUrl}`, user as any).toPromise();
  }

  async createRole(role: CreateRoleModel): Promise<void> {
    const params = this.queryStateService.addClientParameters(role) as any;
    return this.http.post<void>(`${this.usersUrl}/role`, params).toPromise();
  }

  async updateRole(role: UserRoleDto): Promise<void> {
    return this.http.patch<void>(`${this.usersUrl}/role`, role as any).toPromise();
  }
}
