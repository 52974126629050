import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomDatePickerService } from './../../services/custom-datepicker.service';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent {
  @Input() value: Date;
  @Output() valueChange: EventEmitter<Date>; // By convention ...Change facilitates two way binding aka banana in the box
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() placeholder: string;
  @Input() inputStyleClass: string;

  locale: any;
  constructor(customDatePickerService: CustomDatePickerService) {
    this.valueChange = new EventEmitter<Date>();
    this.locale = customDatePickerService.locale;
  }

  dateWasUpdated() {
    this.valueChange.emit(this.value);
  }
}
