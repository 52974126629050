import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNlBe from '@angular/common/locales/nl-BE';
import { LOCALE_ID, NgModule, APP_INITIALIZER, ErrorHandler, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserManager } from 'oidc-client';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { AuthGuard } from './modules/auth/guards';
import { AuthService } from './modules/auth/services';
import { AuthInterceptor } from './modules/auth/utils';
import { OpenIdConnectFactory } from './modules/shared/authentication/openid-connect.factory';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from './modules/store';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {MonacoEditorModule} from "ngx-monaco-editor-v2";
import {ColorPickerModule} from "ngx-color-picker";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeNlBe);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MonacoEditorModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    StoreModule,
    AuthModule,
    ColorPickerModule
  ],
  providers: [
    { provide: 'windowObject', useValue: window },
    { provide: UserManager, useFactory: OpenIdConnectFactory.createUserManager },
    AuthService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'nl-be' },
    {
      // Custom interceptor for catching all http requests
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
