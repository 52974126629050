export enum PlannedActionState {
  New,
  Scheduled,
  BlockedByThreshold,
  Sending,
  RetrySending,
  Paused,
  Failing,
  Closed
}
