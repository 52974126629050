import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConfirmDialog} from 'src/app/model/confirm-dialog.model';
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  dateNow: Date;
  visible: boolean;
  updating: boolean;
  isApproved: boolean;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService) {
  }

  public confirmDialog: ConfirmDialog = {
    optionalNote: null,
    isPlannedNotification: false,
    plannedNotificationDate: null
  };

  @Input() header: string;
  @Input() dialogText: string;
  @Input() isWithNote: boolean;
  @Input() isWithPlannedNotification: boolean;
  @Output() public approved: EventEmitter<ConfirmDialog> = new EventEmitter<ConfirmDialog>();
  @Output() public cancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  show() {
    this.isApproved = false;
    this.dateNow = new Date();
    const plannedNotificationDate = new Date();
    plannedNotificationDate.setMonth(this.dateNow.getMonth() + 1);
    this.confirmDialog = {
      optionalNote: null,
      isPlannedNotification: false,
      plannedNotificationDate
    };
    this.visible = true;
  }

  onHide() {
    if (this.isApproved){
      this.approved.emit(this.confirmDialog);
    } else {
      this.cancelled.emit(true);
    }
  }

  hide() {
    this.visible = false;
  }

  async approve() {
    this.updating = true;
    if (this.isWithNote && this.confirmDialog.optionalNote == null) {
      this.messageService.add(
        {
          severity: 'info',
          summary: this.translateService.instant('FORM.VALIDATION.REQUIRED'),
          detail: this.translateService.instant('FORM.VALIDATION.REQUIRED_REASON'),
          life: 5000
        });
    } else {
      this.isApproved = true;
      this.hide();
    }
    this.updating = false;
  }
}
