import { NotificationType } from 'src/app/model';
import { NotificationDto } from 'src/app/model/dtos/notifications/notification.dto';

export class SetOverviewNotifications {
  static readonly type = '[Notification] SetOverviewNotifications';
  constructor(public notifications: NotificationDto[], public count: number) { }
}

export class ToggleNotificationShowMore {
  static readonly type = '[Notification] Toggle notification show more';
  constructor(public notification: NotificationDto, public inDashboard: boolean) { }
}

export class SetDashboardNotifications {
  static readonly type = '[Notification] SetDashboardNotifications';
  constructor(
    public notifications: NotificationDto[],
    public unread: number,
    public importantUnread: number,
    public notificationType: NotificationType) { }
}
