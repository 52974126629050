import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-mask-input',
  template: `
  <div class="form-group">
    <input #maskInput class="form-control" [mask]="to.maskString" [prefix]="to.prefix" [suffix]="to.suffix"
      [dropSpecialCharacters]="true" [showMaskTyped]="true" [ngClass]="{'is-invalid': showError}"
      [type]="type" [formControl]="formControl" [formlyAttributes]="field"
      (paste)="onPaste($event)">
  </div>
  `,
  styles: [
    '.form-group { margin-bottom: 0px }'
  ]
})
// tslint:disable-next-line: component-class-suffix
export class FormlyFieldMaskInput extends FieldType {
  @ViewChild('maskInput')
  public maskInput: ElementRef;

  get type() {
    return this.to.type || 'text';
  }

  onPaste(event: ClipboardEvent) {
    this.maskInput.nativeElement.value = this.to.onPaste(event);
  }
}
