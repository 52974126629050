import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryStateService } from './query-state.service';

@Injectable()
export class ProtestService {
  private protestUrl = 'protest';
  private httpWithoutInterceptor: HttpClient;

  constructor(private backend: HttpBackend, private http: HttpClient, private queryStateService: QueryStateService) {
    this.httpWithoutInterceptor = new HttpClient(this.backend);
  }

  async protestInvoice(invoiceId: string, content: string): Promise<object> {
    return this.httpWithoutInterceptor.post(`${environment.extApiEndpoint}/protest/${invoiceId}`, {
      content
    }).toPromise();
  }

  async closeProtest(invoiceId: string, closeProtestNote: string): Promise<object> {
    const payload = {
      ...this.queryStateService.addClientParameters({}),
      reason: closeProtestNote
    };
    return this.http.post(`${this.protestUrl}/${invoiceId}`, payload).toPromise();
  }
}
