import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { config } from './config';
import { FormlyFieldAutoCompleteInput } from './helpers/autocomplete-input.component';
import { FormlyFieldDateInput } from './helpers/date-input.component';
import { FormlyFieldMaskInput } from './helpers/mask-input.component';
import { FormlyFieldPhoneNumberInput } from './helpers/phonenumber-input.component';
import { RepeatTypeComponent } from './helpers/repeat-section.type';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {
  placeHolderCharacter: '_',
  thousandSeparator: '.'
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    CalendarModule,
    AutoCompleteModule,
    IntlTelInputNgModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(options),
    FormlyModule.forRoot(config),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    AutoCompleteModule,
    FormlyBootstrapModule,
    CalendarModule
  ],
  declarations: [
    FormlyFieldMaskInput,
    FormlyFieldDateInput,
    FormlyFieldAutoCompleteInput,
    FormlyFieldPhoneNumberInput,
    RepeatTypeComponent
  ]
})
export class UiFormModule {}
