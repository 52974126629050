import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SetPageTitle } from './general.actions';
import { GeneralModel } from './general.model';

@State<GeneralModel>({
  name: 'general',
  defaults: {
    pageTitle: '',
    titleParams: {},
    subtitle: null
  }
})
@Injectable()
export class GeneralState {
  @Action(SetPageTitle)
  setPageTitle(ctx: StateContext<GeneralModel>, action: SetPageTitle) {
    const currState = ctx.getState();
    ctx.setState({
      ...currState,
      pageTitle: action.title,
      titleParams: action.titleParams,
      subtitle: action.subtitle
    });
  }
}
