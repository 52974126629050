import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ClientState } from './client/client.state';
import { GeneralState } from './general/general.state';
import { NotificationState } from './notification/notification.state';
import { PlannedActionState } from './planned-action';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot([ClientState, GeneralState, NotificationState, PlannedActionState]),
    NgxsStoragePluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot()
  ]
})
export class StoreModule { }
