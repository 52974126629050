import {Environment} from "./evironment.interface";

export const environment: Environment = {
  production: true,
  apiEndpoint: 'https://api-acc.sales2cash.be/api',
  extApiEndpoint: 'https://api-acc.sales2cash.be/ext',
  signalREndpoint: 'https://api-acc.sales2cash.be/livefeed',
  authEndpoint: 'https://auth-acc.sales2cash.be/',
  appUri: 'https://demo.sales2cash.be/',
  teamLeaderRedirectAuthCode: 'https://app.teamleader.eu/oauth2/authorize?client_id=8496d3e35371d85168a0cd3a41da2d10&response_type=code&redirect_uri=https://api-acc.sales2cash.be/api/settings/teamleader/connect',
  isabelAuthorizationUrl: 'https://login.common.beta.isabel.be/login/oauth2/realms/root/realms/isabel/authorize?response_type=code&client_id=Sales2Cash&scope=cloudconnect&redirect_uri=https://demo.sales2cash.be/ext/isabel/link?lang=en&ui_locales=en',
  isabelLinkingUrlBuilder: (clientId, state) => `https://auth.beta.isabel.be/auth/realms/isabel/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=https://demo.sales2cash.be/ext/isabel/link?authorized=true&response_type=code&scope=AI%20offline_access&state=${state}&ui_locales=nl`,
  isDemo: true,
  octopusRedirectAuthCode: '',
  yukiRedirectAuthCode: '',
  menuLogoUrl: 'https://satoca-static.s3.eu-central-1.amazonaws.com/circles.png',
  sentryTargets: ["localhost", /^https:\/\/sales2cash\.be/],
  sentryTracesSampleRate: 1.0,
  sentryReplaysSessionSampleRate: 0.1,
  sentryReplaysOnErrorSampleRate: 1.0
};
