import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  PagedPaymentPlan,
  PaymentPlan,
  PaymentPlanFilter,
  PaymentplanInitiationInvoices,
  PaymentPlanSelection,
} from "src/app/model";
import { environment } from "src/environments/environment";
import { QueryStateService } from "../../shared/services";

@Injectable()
export class PaymentPlanService {
  private paymentPlanUrl = "paymentplan";
  constructor(
    private http: HttpClient,
    private queryStateService: QueryStateService,
  ) {}

  async getPaymentPlanInvoices(
    debtorId: string,
  ): Promise<PaymentplanInitiationInvoices> {
    return this.http
      .get<PaymentplanInitiationInvoices>(`${this.paymentPlanUrl}/invoices`, {
        params: {
          debtorId,
          skip: 0,
          take: 100, // Take as many invoices into account for creating this paymentplan
        } as any,
      })
      .toPromise();
  }

  async getPaymentPlanSuggestion(
    selection: PaymentPlanSelection,
  ): Promise<PaymentPlan> {
    const params: any = {
      paymentInterval: selection.interval,
      durationInWeeks: selection.getDurationInWeeks(),
      dossierIds: selection.getDossierIdsFromInvoices(),
      startDate: selection.startDate.toDateString(),
    };

    if (selection.totalAmount !== undefined && selection.totalAmount !== null) {
      params.totalAmount = selection.totalAmount;
    }

    if (
      selection.amountPerInterval !== undefined &&
      selection.amountPerInterval !== null
    ) {
      params.amountPerInterval = selection.amountPerInterval;
    }

    return this.http
      .get<PaymentPlan>(`${this.paymentPlanUrl}/suggestion`, { params })
      .toPromise();
  }

  async setPaymentPlan(
    selection: PaymentPlanSelection,
    file: File | null,
    signedDate: Date | null,
    overrulePhoneNumber?: string,
  ): Promise<any> {
    let formData: FormData = new FormData();

    formData.append("paymentInterval", selection.interval.toString());

    formData.append(
      "durationInWeeks",
      selection.getDurationInWeeks().toString(),
    );

    formData.append("startDate", selection.startDate.toDateString());

    (selection.getDossierIdsFromInvoices() || []).forEach((dossierId) => {
      formData.append("dossierIds", dossierId);
    });

    if (overrulePhoneNumber) {
      formData.append("overrulePhoneNumber", overrulePhoneNumber);
    }

    if (file) {
      formData.append("paymentPlanAgreementFile", file);
    }

    if (signedDate) {
      formData.append("signedDate", signedDate.toDateString());
    }

    if (selection.totalAmount) {
      formData.append("totalAmount", selection.totalAmount.toString());
    }

    if (selection.amountPerInterval) {
      formData.append("amountPerInterval", selection.amountPerInterval.toString());
    }

    formData = this.queryStateService.addClientParamtersToFormData(formData);

    return this.http
      .post(`${this.paymentPlanUrl}/create`, formData)
      .toPromise();
  }

  async getPaymentPlans(filter: PaymentPlanFilter): Promise<PagedPaymentPlan> {
    const params = this.queryStateService.addClientParameters(filter) as any;
    return this.http
      .get<PagedPaymentPlan>(`${this.paymentPlanUrl}`, { params })
      .toPromise();
  }

  async deletePaymentPlan(paymentPlanId: string, note: string): Promise<any> {
    const params = this.queryStateService.addClientParameters({
      note,
    } as any) as any;
    return this.http
      .delete<any>(`${this.paymentPlanUrl}/${paymentPlanId}`, {
        params,
        observe: "response",
      })
      .toPromise();
  }

  getPaymentPlanPdfUrl(paymentPlanId: string): string {
    return `${environment.apiEndpoint}/downloads/paymentplan/pdf/${paymentPlanId}`;
  }
}
