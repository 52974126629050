import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {ClientParametersDto, ReportFilter} from 'src/app/model';
import {CreateVision2PayDto, ExternalConnectionsDto} from '../../../model/dtos/client';
import {QueryStateService} from './query-state.service';
import {GeneralUtil} from "../../../util";


@Injectable()
export class ClientService {
  private clientUrl = 'clients';

  constructor(private http: HttpClient, private queryStateService: QueryStateService) {
  }

  async resetDemoData(email: string, phone: string, groupName: string, clientNamePrefix: string): Promise<object> {
    return this.http.post(`${this.clientUrl}/reset-test-data`, {
      email,
      phone,
      groupName,
      clientNamePrefix
    }).toPromise();
  }

  async getOverviewReport(filter: ReportFilter) {
    const queryScope = {...this.queryStateService.addClientParameters(filter)} as any;
    if (filter.fromDate) {
      queryScope.fromDate = filter.fromDate.toDateString();
    }

    if (filter.toDate) {
      queryScope.toDate = filter.toDate.toDateString();
    }
    const reportFileName = `client_report_${moment(filter.fromDate).format('DD-MM-YYYY')}`;
    const downloadResult = await this.http.get(`${this.clientUrl}/overview-report`,
      {
        params: queryScope as any,
        responseType: 'blob' as 'json'
      }).toPromise();

    if (downloadResult) {
      saveAs(downloadResult, reportFileName);
    }
  }

  async getTwikeyMandateReport() {
    const queryScope = {...this.queryStateService.addClientParameters({})} as any;
    const reportFileName = `twikey_report_${moment().format('DD-MM-YYYY')}.csv`;
    const downloadResult = await this.http.get(`${this.clientUrl}/twikey-report`,
      {
        params: queryScope as any,
        responseType: 'blob' as 'json'
      }).toPromise();

    if (downloadResult) {
      saveAs(downloadResult, reportFileName);
    }
  }

  async getVision2PayDossiersReport(filter: ReportFilter) {
    const queryScope = {
      ...this.queryStateService.addClientParameters(filter),
      fromDate: filter.fromDate.toUTCString(),
      toDate: filter.toDate.toUTCString()
    } as any;
    const readableDate = `${GeneralUtil.getHumanReadableDateString(filter.fromDate, "-")}_${GeneralUtil.getHumanReadableDateString(filter.toDate, "-")}`;
    const reportFileName = `v2p_report_${readableDate}.csv`;
    const downloadResult = await this.http.get(`${this.clientUrl}/v2p-dossiers-report`,
      {
        params: queryScope as any,
        responseType: 'blob' as 'json'
      }).toPromise();

    if (downloadResult) {
      saveAs(downloadResult, reportFileName);
    }
  }

  public async createVision2PayClient(dto: CreateVision2PayDto) {
    return this.http.post(`${this.clientUrl}/vision2pay-client`, dto).toPromise();
  }

  public async getConnectionStates(): Promise<ExternalConnectionsDto> {
    const paramsToSend = this.queryStateService.addClientParameters({}) as any;
    return this.http.get<ExternalConnectionsDto>(`${this.clientUrl}/connection-states`, {params: paramsToSend}).toPromise();
  }

  async getClientParameters(): Promise<ClientParametersDto> {
    const paramsToSend = this.queryStateService.addClientParameters({}) as any;
    return this.http.get<ClientParametersDto>(`${this.clientUrl}/parameters/`, { params: paramsToSend}).toPromise();
  }

  public async updateClientParameters(dto: ClientParametersDto) {
    dto.clientId = this.queryStateService.getClientId();
    return this.http.post<void>(`${this.clientUrl}/parameters/`, dto).toPromise();
  }
}
