import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MomentModule } from 'ngx-moment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService, SharedModule as PrimeSharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ActionDefinitionService } from '../main/services/action-definition.service';
import { PaymentPlanService } from '../main/services/paymentplan.service';
import {
  ConfirmDialogComponent,
  ContactCardComponent,
  ContactCardListComponent,
  CustomDatepickerComponent,
  GeneralTableComponent,
  HeaderTableComponent,
  LabelDossierStateComponent,
  LabelInvoiceQuickStateComponent,
  LinkDebtorToDossierComponent,
  NotFoundComponent,
  PaymentPlanDetailComponent,
  PaymentplanSimulationComponent,
  RegisterVision2PayClientComponent,
  SwitchButtonComponent
} from './components';
import { AutofocusDirective, CommChannelIconDirective, QuickStateColorDirective, ClickOutsideDirective } from './directives';
import {
  ActionFlowDocTypePipe,
  ActionFlowTypePipe,
  CompanyLabelPipe,
  ConnectivePackageStatePipe,
  CountryPipe,
  CurrencyFormatPipe,
  DossierStatePipe,
  InvoiceFlowPipe,
  InvoiceQuickStatePipe,
  InvoiceQuickStepPipe,
  LanguagePipe, OptionalConditionPipe,
  PaymentMethodPipe,
  PermissionEnumPipe,
  PermissionPipe, PersonRolePipe,
  PhoneNumberPipe,
  SafeHtmlPipe,
  VatNumberPipe
} from './pipes';
import { PreferenceService, ProtestService, UserService } from './services';
import { ClientService } from './services/client.service';
import { QueryStateService } from './services/query-state.service';
import { SettingsService } from './services/settings.service';
import { TableFilterService } from './services/table-filter.service';
import { UiFormModule } from "../ui-form/ui-form.module";
import { ModernSwitchButtonComponent } from './components/modern-switch-button/modern-switch-button.component';

@NgModule({
  declarations: [
    CurrencyFormatPipe,
    CompanyLabelPipe,
    AutofocusDirective,
    SwitchButtonComponent,
    PaymentplanSimulationComponent,
    InvoiceFlowPipe,
    InvoiceQuickStatePipe,
    DossierStatePipe,
    ConnectivePackageStatePipe,
    PaymentMethodPipe,
    PhoneNumberPipe,
    VatNumberPipe,
    PermissionPipe,
    NotFoundComponent,
    PermissionEnumPipe,
    OptionalConditionPipe,
    PaymentPlanDetailComponent,
    CustomDatepickerComponent,
    SafeHtmlPipe,
    LabelInvoiceQuickStateComponent,
    GeneralTableComponent,
    HeaderTableComponent,
    LabelDossierStateComponent,
    ConfirmDialogComponent,
    LanguagePipe,
    LinkDebtorToDossierComponent,
    ContactCardComponent,
    CountryPipe,
    ContactCardListComponent,
    QuickStateColorDirective,
    CommChannelIconDirective,
    ActionFlowTypePipe,
    ActionFlowDocTypePipe,
    PersonRolePipe,
    RegisterVision2PayClientComponent,
    ClickOutsideDirective,
    ModernSwitchButtonComponent,
  ],
  imports: [
    UiFormModule,
    RouterModule,
    PrimeSharedModule,
    TranslateModule,
    MomentModule,
    NgxSliderModule,
    NgxPermissionsModule,
    CollapseModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    FontAwesomeModule,
    TableModule,
    TooltipModule,
    DialogModule,
  ],
  exports: [
    TranslateModule,
    TooltipModule,
    NgxSliderModule,
    CurrencyFormatPipe,
    CompanyLabelPipe,
    MomentModule,
    AutofocusDirective,
    PaginationModule,
    CollapseModule,
    PaymentplanSimulationComponent,
    SwitchButtonComponent,
    FontAwesomeModule,
    InvoiceFlowPipe,
    InvoiceQuickStatePipe,
    DossierStatePipe,
    ConnectivePackageStatePipe,
    PaymentMethodPipe,
    TableModule,
    PhoneNumberPipe,
    VatNumberPipe,
    PermissionPipe,
    NotFoundComponent,
    PermissionEnumPipe,
    PaymentPlanDetailComponent,
    CustomDatepickerComponent,
    FileUploadModule,
    SafeHtmlPipe,
    LabelInvoiceQuickStateComponent,
    GeneralTableComponent,
    HeaderTableComponent,
    LabelDossierStateComponent,
    ConfirmDialogComponent,
    LanguagePipe,
    LinkDebtorToDossierComponent,
    ContactCardListComponent,
    QuickStateColorDirective,
    CommChannelIconDirective,
    ClickOutsideDirective,
    ActionFlowDocTypePipe,
    ActionFlowTypePipe,
    PersonRolePipe,
    RegisterVision2PayClientComponent,
    OptionalConditionPipe,
    ContactCardComponent,
    ModernSwitchButtonComponent
  ],
  providers: [
    QueryStateService,
    TableFilterService,
    ClientService,
    SettingsService,
    InvoiceQuickStatePipe,
    DossierStatePipe,
    CompanyLabelPipe,
    VatNumberPipe,
    ActionDefinitionService,
    MessageService,
    PaymentPlanService,
    LanguagePipe,
    PreferenceService,
    UserService,
    ProtestService
  ]
})
export class SharedModule {
}
