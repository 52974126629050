import {PagedFilterModel} from './paged-filter.model';

export class NotificationFilter extends PagedFilterModel {
  isRead: boolean;
  isImportant: boolean;
  onlyForInternalFollowUp: boolean;
  notificationType: number;
  duplicateReferences: boolean;

  constructor(pageNumber: number,
              pageSize: number,
              orderBy: string,
              orderDirection: string,
              clientId?: string,
              clientGroupId?: string) {
    super(pageNumber, pageSize, orderBy, orderDirection, clientId, clientGroupId);
  }

  reset() {
    this.isRead = undefined;
    this.isImportant = undefined;
    this.onlyForInternalFollowUp = false;
    this.notificationType = undefined;
    this.duplicateReferences = false;
  }
}


