import { Component, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { debounceTime } from 'rxjs/operators';
import { DebtorFilter } from 'src/app/model/filters/debtor-filter.model';
import { PagedDebtor } from 'src/app/model/paged-models/paged-debtor.model';
import { DebtorService } from 'src/app/modules/main/services/debtor.service';
import { DossierService } from 'src/app/modules/main/services/dossier.service';
import { TableFilterService } from '../../services/table-filter.service';

@Component({
  selector: 'app-link-debtor-to-dossier',
  templateUrl: './link-debtor-to-dossier.component.html',
  styleUrls: ['./link-debtor-to-dossier.component.scss']
})
export class LinkDebtorToDossierComponent {
  public visible = false;
  public isLinking = false;
  public dossierId: string;
  public initialLoadDone: boolean;
  private searchQueryUpdated = new EventEmitter<string>();
  public currentFilter: DebtorFilter;
  public pagedDebtors: PagedDebtor;
  public selectedDebtorId: string;

  constructor(
    private debtorService: DebtorService,
    private tableFilterService: TableFilterService,
    private dossierService: DossierService,
    private translateService: TranslateService,
    private messageService: MessageService) {
    this.searchQueryUpdated.pipe(debounceTime(500)).subscribe(async (newNameQuery) => {
      this.currentFilter = this.tableFilterService.getTableState('debtorsLinkToDossierOverviewTable', DebtorFilter);
      this.currentFilter.legalName = newNameQuery;
      await this.loadDebtors();
    });
  }

  public async show(dossierId: string) {
    this.currentFilter = this.tableFilterService.getTableState('debtorsLinkToDossierOverviewTable', DebtorFilter);
    await this.loadDebtors();
    this.dossierId = dossierId;
    this.visible = true;
  }

  public close() {
    this.tableFilterService.resetTableFilter('debtorsLinkToDossierOverviewTable');
    this.selectedDebtorId = null;
    this.visible = false;
  }

  async linkDebtorToDossier() {
    this.isLinking = true;
    const response = await this.dossierService.linkDebtorToDossier(this.dossierId, this.selectedDebtorId);
    if (response.ok) {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('DOSSIER.LINK_DEBTOR.SUCCESS'),
        detail: this.translateService.instant('DOSSIER.LINK_DEBTOR.SUCCESSDETAIL'),
        life: 5000
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('DOSSIER.LINK_DEBTOR.ERROR'),
        detail: this.translateService.instant('DOSSIER.LINK_DEBTOR.ERRORDETAIL'),
        life: 5000
      });
    }
    this.isLinking = false;
    this.close();
  }

  public updateSearchQuery(newValue: string) {
    this.searchQueryUpdated.emit(newValue);
  }

  async tableLazyLoad(event: any) {
    const filterWasUpdated = this.tableFilterService.updateTableFilter('debtorsLinkToDossierOverviewTable', this.currentFilter, event);
    if (!filterWasUpdated && this.initialLoadDone) {
      return;
    }
    this.initialLoadDone = true;
    await this.loadDebtors();
  }

  public async loadDebtors() {
    this.pagedDebtors = await this.debtorService.getDebtors(this.currentFilter);
  }

  public async onDebtorSelect(debtorId: string) {
    this.selectedDebtorId = debtorId;
  }
}
