export class ClientModel {
  clientGroupIds: string[];
  clientIds: string[];

  constructor(clientGroupIds: string[], clientIds: string[]) {
    this.clientGroupIds = clientGroupIds;
    this.clientIds = clientIds;
  }
}
export class SelectionInfo {
  selectedClientId: string;
  selectedClientGroupId: string;
  selectedName: string;
  selectedNameNeedsTranslate: boolean;
  isClientGroupSelected: boolean;
  isClientSelected: boolean;
  isAllSelected: boolean; // When 'All' is clicked on the sidebar
  isAllGroupsSelected: boolean; // When 'All groups' are clicked on the sidebar

  constructor() {
    this.selectedClientId = null;
    this.selectedClientGroupId = null;
    this.selectedName = '';
    this.isClientGroupSelected = false;
    this.isClientSelected = false;
    this.selectedNameNeedsTranslate = false;
    this.isAllSelected = false;
    this.isAllGroupsSelected = false;
  }
}
