import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {AutoComplete} from 'primeng/autocomplete';
import {SearchFilter} from 'src/app/model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-autocomplete-input',
  template: `
    <div class="form-group formly">
      <p-autoComplete *ngIf="!to.mask"
                      [placeholder]='to.placeholder'
                      [inputStyleClass]="showError ? 'form-control is-invalid' : 'form-control'"
                      ngDefaultControl
                      [formControl]="formControl"
                      [formlyAttributes]="field"
                      [suggestions]="suggestions"
                      (completeMethod)="search()"
                      (onSelect)="selectedValue($event)"
                      (paste)="onPaste($event)">
        <ng-template let-value pTemplate="item">
          <div class="ui-helper-clearfix">
            <span class="d-block">{{ value.info }}</span>
            <span *ngIf="value.extraInfo" class="light-small-text">
            {{ value.extraInfo }}
          </span>
          </div>
        </ng-template>
      </p-autoComplete>
      <p-autoComplete *ngIf="to.mask"
                      [placeholder]='to.placeholder'
                      [inputStyleClass]="showError ? 'form-control is-invalid' : 'form-control'"
                      ngDefaultControl
                      [suggestions]="suggestions"
                      (completeMethod)="search()"
                      (onSelect)="selectedValue($event)"
                      (paste)="onPaste($event)">
        <ng-template let-value pTemplate="item">
          <div class="ui-helper-clearfix">
            <span class="d-block">{{ value.info }}</span>
            <span *ngIf="value.extraInfo" class="light-small-text">
            {{ value.extraInfo }}
          </span>
          </div>
        </ng-template>
      </p-autoComplete>
      <input #maskInput *ngIf="to.mask" style="position: absolute; top:0;" class="form-control" [mask]="to.mask"
             [prefix]="to.prefix"
             [suffix]="to.suffix" [dropSpecialCharacters]="true" [showMaskTyped]="true" [type]="type"
             [placeholder]='to.placeholder'
             [formControl]="formControl" [formlyAttributes]="field" (focus)="onMaskFocus($event)"
             [ngClass]="{'is-invalid': showError}"
             (focusout)="onMaskBlur($event)" (input)="onMaskInput($event)" (click)="onMaskClick($event)"
             (keydown)="onMaskKeyDown($event)"
             (paste)="onPaste($event)">
    </div>
  `,
  styles: [
    '.form-group { margin-bottom: 0px; position: relative; }'
  ]
})

// tslint:disable-next-line: component-class-suffix
export class FormlyFieldAutoCompleteInput extends FieldType implements AfterViewInit {

  @ViewChild(AutoComplete)
  public autoComplete: AutoComplete;

  @ViewChild('maskInput')
  public maskInput: ElementRef;

  suggestions: any[] = [];

  constructor() {
    super();
  }

  get type() {
    return this.to.type || 'text';
  }

  ngAfterViewInit() {
    if (this.to.mask) {
      this.autoComplete.inputEL = this.maskInput;
    }
  }

  onMaskFocus(event) {
    this.autoComplete.onInputFocus(event);
  }

  onMaskBlur(event) {
    this.autoComplete.onInputBlur(event);
  }

  onMaskInput(event) {
    this.autoComplete.inputFieldValue = this.maskInput.nativeElement.value;
    this.autoComplete.onInput(event);
  }

  onMaskClick(event) {
    this.autoComplete.onInputClick(event);
  }

  onMaskKeyDown(event) {
    this.autoComplete.onKeydown(event);
  }

  search() {
    let searchValue = this.to.mask ? this.maskInput.nativeElement.value : this.autoComplete.inputEL.nativeElement.value;
    if (this.to.mask) {
      // Remove suffix (it will not make a change for possible matches)
      if (this.to.suffix && searchValue.indexOf(this.to.suffix) === searchValue.length - this.to.suffix.length) {
        searchValue = searchValue.slice(0, searchValue.indexOf(this.to.suffix));
      }
      // Remove special characters and '_' placeholder
      searchValue = searchValue.replace(/_|\s|\.|-|\/|\(|\)|\:|\+|,|@|\[|\]|'|"/gm, '');
    }

    // Search key example:
    // You want to search on a debtor 'legalName', but on select, you want to have the debtor id in the model
    const searchKey = this.to.additionalProperties.searchKey;

    this.to.additionalProperties.searchFunction(new SearchFilter(searchValue, searchKey.toString()))
      .then((result) => {
        // Set (extra) info
        for (const suggestion of result) {
          suggestion.info = this.to.additionalProperties.infoBuilder(suggestion);
          suggestion.extraInfo = this.to.additionalProperties.extraInfoBuilder(suggestion);
        }
        this.suggestions = result;
        if (this.suggestions && this.suggestions.length && this.to.mask) {
          this.autoComplete.inputEL = this.maskInput;
          this.autoComplete.show();
        }
      });
  }

  selectedValue(value: any) {
    if (this.to.mask) {
      this.autoComplete.inputEL = this.maskInput;
      this.autoComplete.inputFieldValue = this.maskInput.nativeElement.value;
    }
    Object.keys(this.form.controls).forEach(key => {
      if (!!value[key]) {
        this.form.controls[key].patchValue(value[key]);
      }
    });
  }

  returnItemValueWithPrefix(value: string) {
    let val = '';
    if (this.to.prefix) {
      val = this.to.prefix;
    }
    val += value;
    if (this.to.suffix) {
      val += this.to.suffix;
    }
    return val;
  }

  onPaste(event: ClipboardEvent) {
    if (this.to.mask) {
      this.autoComplete.inputFieldValue = this.maskInput.nativeElement.value = this.to.onPaste(event);
    }
  }
}
