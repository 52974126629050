import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {isNil, omitBy} from 'lodash-es';
import { ClientFilterModel } from "../../../model";
import { ClientModel } from "../../store";

@Injectable()
export class QueryStateService {
  constructor(private store: Store) {
  }

  public addClientParameters(queryParams: ClientFilterModel): ClientFilterModel {
    const currentClientModel = this.store.selectSnapshot<ClientModel>(state => state.client);
    // No client nor group selected, this shouldn't happen normally
    if (!currentClientModel) {
      return omitBy(queryParams, isNil); // Remove parameters that are null/undefined
    }
    // No clients selected, only group(s)
    if (currentClientModel.clientIds && currentClientModel.clientIds.includes('*')) {
      queryParams = {...queryParams, clientGroupIds: currentClientModel.clientGroupIds};
    } else {
      // No group selected only client(s)
      queryParams = {...queryParams, clientIds: currentClientModel.clientIds};
    }
    queryParams = omitBy(queryParams, isNil); // Remove parameters that are null/undefined
    return queryParams;
  }

  public getClientId(){
    const currentClientModel = this.store.selectSnapshot<ClientModel>(state => state.client);
    //we will call methods that use this function only when we have one client
    if(currentClientModel.clientIds.length == 1){
      return currentClientModel.clientIds[0];
    }
    return null;
  }

  public addClientParamtersToFormData(formData: FormData): FormData {
    const currentClientModel = this.store.selectSnapshot<ClientModel>(state => state.client);
    // No client nor group selected, this shouldn't happen normally
    if (!currentClientModel) {
      return formData;
    }
    // No clients selected, only group(s) -> client admin account
    if (currentClientModel.clientIds && currentClientModel.clientIds.includes('*')) {
      (currentClientModel.clientGroupIds || []).forEach(clientGroupId => {
        formData.append('clientGroupIds', clientGroupId);
      });
    } else {
      // No group selected only client(s) -> client account
      (currentClientModel.clientIds || []).forEach(clientId => {
        formData.append('clientIds', clientId);
      });
    }
    return formData;
  }
}
