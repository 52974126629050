import { NgModule } from '@angular/core';
import { LivefeedService } from '../shared/services';
import { SharedModule } from '../shared/shared.module';
import { UiFormModule } from "../ui-form/ui-form.module";

@NgModule({
  imports: [
    SharedModule,
    UiFormModule
  ],
  providers: [
    LivefeedService
  ]
})
export class AuthModule { }
