import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {
  SetDashboardNotifications,
  SetOverviewNotifications,
  ToggleNotificationShowMore
} from './notification.actions';
import {NotificationModel} from './notification.model';
import {patch, updateItem} from "@ngxs/store/operators";
import {NotificationDto} from "../../../model";

@State<NotificationModel>({
  name: 'notification',
  defaults: {
    overview: [],
    count: 0,
    dashboard: [],
    unread: 0,
    importantUnread: 0
  }
})
@Injectable()
export class NotificationState {
  @Action(ToggleNotificationShowMore)
  toggleNotificationShowMore(ctx: StateContext<NotificationModel>, payload: ToggleNotificationShowMore) {
    if (payload.inDashboard) {
      ctx.setState(
        patch({
          dashboard: updateItem<NotificationDto>(item => item.id === payload.notification.id,
            patch({showMore: !payload.notification.showMore}))
        })
      );
    } else {
      ctx.setState(
        patch({
          overview: updateItem<NotificationDto>(item => item.id === payload.notification.id,
            patch({showMore: !payload.notification.showMore}))
        })
      );
    }
  }

  @Action(SetOverviewNotifications)
  setOverviewNotifications(ctx: StateContext<NotificationModel>, payload: SetOverviewNotifications) {
    const currState = ctx.getState();
    ctx.setState({
      ...currState,
      overview: payload.notifications,
      count: payload.count
    });
  }

  @Action(SetDashboardNotifications)
  setNotificationsDashboard(ctx: StateContext<NotificationModel>, payload: SetDashboardNotifications) {
    const currState = ctx.getState();
    ctx.setState({
      ...currState,
      dashboard: payload.notifications,
      unread: payload.unread,
      importantUnread: payload.importantUnread
    });
  }
}
