<div class="form-main large nospinners">
  <h3 translate>REGISTERVISION2PAY.TITLE</h3>
  <form *ngIf="formData" [formGroup]="form" (ngSubmit)="registerClient()">
    <formly-form [model]="formData" [fields]="fields" [form]="form" root-el="form"></formly-form>
    <div class="row mt-3">
      <div class="col-auto">
        <button type="submit" class="btn btn-primary" [disabled]="submitting">
                    <span *ngIf="submitting" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true">
                    </span>
          <span *ngIf="!submitting" translate>REGISTERVISION2PAY.SUBMIT</span>
        </button>
      </div>
      <div *ngIf="showError" class="col-auto my-auto">
        <span class="text-danger">{{errorContent}}</span>
      </div>
    </div>
  </form>
</div>
